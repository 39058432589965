import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import mediaAPI from "../../api/mediaAPI";
import MiscFunctions from "../../helpers/MiscFunctions";
import Swal from "sweetalert2";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
} from "@chakra-ui/react";

class CommentUploadedFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.commentEdit ? true : false,
      fileIndex: this.props.fileIndex,
      fileUrl: null,
      medias:
        this.props.commentEdit === true
          ? this.props.commentMedias
          : !MiscFunctions.isUndefined(this.props.clickedComment)
            ? this.props.clickedComment.medias
            : [],
      clickedComment: this.props.clickedComment,
      commentMedias: null,
      clickedComment: undefined,
    };
  }

  toggleAccordion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  deleteUploadedFile = async (id, medias, index) => {
    if (this.props.commentUpdate) {
      console.log("warning");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4baaac",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          mediaAPI.deleteMediaById(id).then((response) => {
            const deleteFile = medias.splice(index, 1);
            this.setState({ medias: medias });
            if (this.props.commentEdit !== true) {
              this.props.setUpdateMediaFile(!this.props.updateMediaFile);
            }
          });
          this.props.setUpdateMediaFile(!this.props.updateMediaFile);
        }
      });
    } else {
      const data = await mediaAPI.deleteMediaById(id);
      const deleteFile = medias.splice(index, 1);
      this.setState({ medias: medias });
      if (this.props.commentEdit !== true) {
        this.props.setUpdateMediaFile(!this.props.updateMediaFile);
      }
    }
  };

  checkDeletedMedia = (values) => {
    if (!MiscFunctions.isNull(values) && !MiscFunctions.isUndefined(values)) {
      let data = values.filter(
        (value) => value.description !== "Deleted Media"
      );
      this.setState({ medias: data });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.commentMedias !== this.props.commentMedias) {
      this.setState({ commentMedias: this.props.commentMedias });
      if (!MiscFunctions.isUndefined(this.props.commentMedias)) {
        this.checkDeletedMedia(this.props.commentMedias);
      }
    }
    if(prevProps.clickedComment !== this.props.clickedComment){
      if(!this.props.editCommentReply){
        this.setState({clickedComment: this.props.clickedComment})
        this.checkDeletedMedia(this.props.clickedComment.medias);
      }else if(this.props.editCommentReply===true && !MiscFunctions.isUndefined(this.props.commnetForReply) && this.props.commnetForReply.id === this.props.clickedComment.id){
        this.setState({clickedComment: this.props.clickedComment})
        this.checkDeletedMedia(this.props.clickedComment.medias);
        }

    }
  }

  componentDidMount() {
    if (!MiscFunctions.isUndefined(this.props.clickedComment)) {
      this.checkDeletedMedia(this.props.clickedComment.medias);
    }
  }

  render() {
    let counter = 0;
    return (
      !MiscFunctions.isNull(this.state.medias) &&
      !MiscFunctions.isUndefined(this.state.medias) && <div className="comment-files-accordion">
        <div
          className="comment-files-accordion-header"
          onClick={this.toggleAccordion}
        >
          <span className="comment-files-title">
            All Files
            <span className="file-count">
              (
              {!MiscFunctions.isNull(this.state.medias) &&
                !MiscFunctions.isUndefined(this.state.medias)
                ? this.state.medias.length
                : "0"}{" "}
              files)
            </span>
          </span>
          <span
            className={`drop-down-icon  ${this.state.isOpen ? "open" : ""}`}
          ></span>
        </div>
        <div
          className={`comment-files-accordion-content ${this.state.isOpen ? "open" : ""
            }`}
          style={{
            maxHeight: this.state.isOpen ? "500px" : "0px",
            opacity: this.state.isOpen ? 1 : 0,
            transition: "max-height 0.5s ease-in-out, opacity 0.5s ease-in-out",
          }}
        >
          {this.state.medias !== null && this.state.medias !== undefined && (
            <ul
              className={`${this.state.isOpen ? "open" : ""}`}
              style={{ zIndex: 100 }}
            >
              {this.state.medias.map((file, i) => {
                let k = counter;
                let position = "bottom";
                if (this.props.checkCommentIsReply) {
                  k = counter++;
                  position = "top";
                } else {
                  k = counter--;
                  position = "bottom";
                }
                return (
                  <li
                    key={file.id}
                    onClick={() => {
                      if (!this.props.commentEdit) {
                        this.props.setFileIndex(i);
                      }
                    }}
                    style={{ zIndex: k }}
                  >
                    <span className="comment-file-details">
                      {file.type !== "picture" ? (
                        <span
                          className="document-icon"
                          onClick={() =>
                            this.props.commentEdit !== true
                              ? this.props.setMiniSlider(true)
                              : ""
                          }
                        ></span>
                      ) : (
                        <>
                          <Box>
                            <Popover
                              isLazy
                              trigger="hover"
                              strategy={"fixed"}
                              placement={position}
                              flip={false}
                            >
                              <PopoverTrigger>
                                <span
                                  className="comment-file-thumb"
                                  style={{
                                    backgroundImage: `url(${file.url})`,
                                  }}
                                  onClick={() =>
                                    this.props.commentEdit !== true
                                      ? this.props.setMiniSlider(true)
                                      : ""
                                  }
                                ></span>
                              </PopoverTrigger>
                              <PopoverContent
                                w="180px"
                                h="100px"
                                p={0}
                                m={0}
                                bg={"transparent"}
                              >
                                <PopoverBody p={0} borderRightRadius="5">
                                  <img
                                    src={`${file.url}`}
                                    style={{ borderRadius: "5px" }}
                                    alt=""
                                  />
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Box>
                        </>
                      )}
                      <span className="comment-file-name">
                        {file.description.split("/").length > 1
                          ? file.description.split("/")[1]
                          : file.description}
                      </span>
                      <span className="comment-file-size"></span>
                    </span>
                    {this.props.commentEdit === true ||
                      this.props.commentReply === true ? (
                      <div className="func_for_uploaded_file">
                        <span className="comment_upload_check"></span>
                        <span
                          className="delete_uploaded_file"
                          onClick={() => {
                            this.deleteUploadedFile(
                              file.id,
                              this.state.medias,
                              i
                            );
                          }}
                        ></span>
                      </div>
                    ) : (
                      <div className="file_download_delete">
                        <a
                          href={file.url}
                          download="file"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon-download-small"></span>
                        </a>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CommentUploadedFile);
